import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SelectLanguage from "./components/SelectLanguage";
import PersonnelInfo from "./components/PersonnelInfo";
import QuestionWizard from "./components/QuestionQizard";
import DebugApi from "./components/DebugApi";

import "./i18n";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProductCatalogue from "./components/ProductCatalogue";
import ProductSeller from "./components/ProductSellers";
import SplashScreen from "./components/SplashScreen";
import Intermediate from "./components/wizardSteps/Intermediate";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/language" element={<SelectLanguage />} />
        <Route path="/profile" element={<PersonnelInfo />} />
        <Route path="/wizard" element={<QuestionWizard />} />
        <Route path="/intermediate" element={<Intermediate />} />
        <Route path="/product-catalogue" element={<ProductCatalogue />} />
        <Route path="/product-sellers" element={<ProductSeller />} />
        <Route path="/debugapi" element={<DebugApi />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
