// export const API_ENDPOINT = "https://d8487245-1689-4715-b812-f8631e195f21.mock.pstmn.io";
export const API_ENDPOINT = "https://caress.rinaldiservice.it/caressapi/api";

export const COUNTRIES = [
  { name: "Italian", code: "it", flag: "it.svg" },
  { name: "French", code: "fr", flag: "fr.svg" },
  { name: "German", code: "de", flag: "de.svg" },
  { name: "English", code: "en", flag: "en.svg" },
  { name: "Chinese", code: "zh", flag: "zh.svg" },
  { name: "Turkish", code: "tr", flag: "tr.svg" },
  { name: "Irish", code: "ga", flag: "ga.svg" },
];

export const GENDER = {
  male: "Male",
  female: "Female",
};

export const MATTRESS_TYPES = [
  {
    label: "Single",
    value: 1,
    icon: "mattress-single.svg",
  },
  {
    label: "Double",
    value: 3,
    icon: "mattress-double.svg",
  },
];

export const ENVIORMENT_CONDITION = [
  {
    label: "Mild Place",
    value: 1,
    icon: "mild-place.svg",
  },
  {
    label: "Cool Place",
    value: 2,
    icon: "cool-place.svg",
  },
  {
    label: "Warm Place",
    value: 3,
    icon: "warm-place.svg",
  },
];

export const COMFORT_PREFERENCE = [
  {
    label: "Mild",
    value: 1,
    icon: "mild.svg",
  },
  {
    label: "Fresh",
    value: 2,
    icon: "fresh.svg",
  },
  {
    label: "Warm",
    value: 3,
    icon: "warm.svg",
  },
];

export const MUSCULOSKELETAL_PAIN = [
  {
    label: "No Pain",
    value: 1,
    icon: "no-pain",
  },
  {
    label: "Cervical",
    value: 2,
    icon: "cervical",
  },
  {
    label: "Back",
    value: 3,
    icon: "back",
  },
  {
    label: "Joints",
    value: 4,
    icon: "joints",
  },
];

export const POSTURE_OF_SLEEPING = [
  {
    label: "Foetus",
    value: 1,
    icon: "foetus",
  },
  {
    label: "Log",
    value: 1,
    icon: "log",
  },
  {
    label: "Yearner",
    value: 1,
    icon: "yearner",
  },
  {
    label: "Soldier",
    value: 0,
    icon: "soldier",
  },
  {
    label: "Freefaller",
    value: 0,
    icon: "freefaller",
  },
  {
    label: "Starfish",
    value: 0,
    icon: "starfish",
  },
];
