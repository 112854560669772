import HeadingTitle from "../customHtml/HeadingTitle";
import React from "react";
import { GENDER } from "../../constants/shared";
import { useTranslation } from "react-i18next";

const SelectGender = ({ step, genderHandler }) => {
  const { t } = useTranslation();

  return (
    <>
      {step === 1 && (
        <>
          <HeadingTitle title="Select Gender" classNames="txt-white" />
          <div className="d-flex justify-content-center">
            <img src="/assets/images/gender-male-female.svg" alt="" className="w-100" />
          </div>
          <div className="select-gender-button-container">
            <button className="primary-button" onClick={() => genderHandler(GENDER.male)}>
              {t("Male")}
            </button>
            <button className="primary-button" onClick={() => genderHandler(GENDER.female)}>
              {t("Female")}
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default SelectGender;
