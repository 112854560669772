import HeadingTitle from "./customHtml/HeadingTitle";
import { COUNTRIES } from "../constants/shared";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { get, set, update, del } from "idb-keyval";

const SelectLanguage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state?.from) {
      get("personnelInfo").then((personnelInfo) => {
        if (personnelInfo?.lat) {
          update("wizard", (data) => {
            let newData = { ...data, step: 2 };
            return newData;
          });
          navigate("/wizard");
        }
      });
    }

    if (location?.state?.from === "personnelInfo") {
      del("language");
    }
  }, []);

  const changeLanguage = (code) => {
    set("language", code);
    i18n.changeLanguage(code);
    navigate("/profile");
  };

  return (
    <div className="imgbg-body-container">
      <div className="content-wrapper">
        <HeadingTitle title="Select your language" classNames="txt-white" />
        <div className="custom-card language-card mt-5">
          <div className="row p-18">
            {COUNTRIES.map((country, index) => (
              <LanguageItem country={country} changeLanguage={changeLanguage} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const LanguageItem = ({ country, changeLanguage }) => {
  return (
    <div className="col-6 col-md-4 col-lg-3">
      <div
        className="py-4 px-sm-3 px-lg-4 country-item"
        onClick={() => changeLanguage(country.code)}
      >
        <div className="country-img-wrapper d-inline-block">
          <img src={`/assets/images/${country.flag}`} alt={country.name} className="w-100" />
        </div>
        <div className="d-inline-block">
          <p className="country-name mb-0">{country.name}</p>
        </div>
      </div>
    </div>
  );
};

export default SelectLanguage;
