import { API_ENDPOINT } from "../constants/shared";
import axios from "axios";

const DebugApi = () => {
  const register = () => {
    let data = {
      name: "firstName",
      familyName: "lastName",
      email: "email",
      phone: "phoneNumber",
      lat: 40.678,
      lon: 14.759,
      country: "italia",
      region: "campania",
      province: "avellino",
      municipality: "avellino",
      address1: "indi",
      address2: "indi",
    };
    axios
      .post(`${API_ENDPOINT}/register`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      });
  }

  const wizard = () => {
    const data = {
      model: null,
      description: null,
      code: null,
      tradeMark: null,
      link: null,
      gender: 1,
      height: 1,
      weight: 1,
      posture: 1,
      pic: null,
    };

    axios
      .post(`${API_ENDPOINT}/wizard`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("data from wizard api");
        console.log(res.data);
      });
  }

  const resellers = () => {
    const data = {
      model: "model",
      tradeMark: "tradeMark",
      codeCli: null,
      description: null,
      address1: null,
      zipCode: null,
      municipality: null,
      province: null,
    };

    axios
      .post(`${API_ENDPOINT}/resellers`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("data from reseller api");
        console.log(res.data);
      });
  }
  
  return (
    <div>
      <button onClick={() => register()}>register</button>
      <button onClick={() => wizard()}>wizard</button>
      <button onClick={() => resellers()}>resellers</button>
    </div>
  )
}

export default DebugApi;