import HeadingTitle from "../customHtml/HeadingTitle";
import React from "react";
import { useTranslation } from "react-i18next";
import { GENDER, MATTRESS_TYPES } from "../../constants/shared";
import { STEP } from "../QuestionQizard";
import { Select, FormControl, MenuItem } from "@mui/material";

const WeightAndHeight = ({ step, weightHandler, heightHandler, mattress, gender }) => {
  const { t } = useTranslation();

  const weightMale = [
    { label: t("Weight"), value: 0 },
    { label: "0-62", value: 1 },
    { label: "63-75", value: 2 },
    { label: "76-92", value: 3 },
    { label: "93-105", value: 4 },
    { label: ">106", value: 5 },
  ];

  const weightFemale = [
    { label: t("Weight"), value: 0 },
    { label: "0-54", value: 1 },
    { label: "55-67", value: 2 },
    { label: "68-79", value: 3 },
    { label: "80-105", value: 4 },
    { label: ">106", value: 5 },
  ];

  const heightFemale = [
    { label: t("Height"), value: 0 },
    { label: "<55", value: 1 },
    { label: "156-170", value: 2 },
    { label: ">170", value: 3 },
  ];

  const heightMale = [
    { label: t("Height"), value: 0 },
    { label: "<65", value: 1 },
    { label: "166-178", value: 2 },
    { label: ">179", value: 3 },
  ];

  return (
    <>
      {(step === STEP.FIRST_WEIGHT_HEIGHT_SELECT || step === STEP.SECOND_WEIGHT_HEIGHT_SELECT) && (
        <div>
          <div className="scale-heading">
            <HeadingTitle
              title={
                step === STEP.FIRST_WEIGHT_HEIGHT_SELECT && mattress === MATTRESS_TYPES[1].value
                  ? `Select Weight and Height`
                  : step === STEP.SECOND_WEIGHT_HEIGHT_SELECT &&
                    mattress === MATTRESS_TYPES[1].value
                  ? `Select Weight and Height of the other person`
                  : `Select Weight and Height`
              }
              classNames="txt-white"
            />
          </div>
          <div className="d-flex justify-content-center wizard-scale-img-container">
            <img src={`/assets/images/scale-${gender}.svg`} alt="" className="w-100" />
          </div>
          <form>
            <div className="wizard-scale-container mb-4 mt-2">
              <div className="wizard-scale-height">
                <label htmlFor="">{t("Height")}</label>
                <FormControl fullWidth>
                  <Select
                    labelId="height-select-label"
                    id="height-select-label"
                    placeholder={t("Height")}
                    onChange={(e) => heightHandler(e.target.value)}
                    required
                    defaultValue={
                      gender === GENDER.male ? heightMale[0].value : heightFemale[0].value
                    }
                  >
                    {gender === GENDER.male
                      ? heightMale.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                      : heightFemale.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </div>
              <div className="wizard-scale-weight">
                <label htmlFor="">{t("Weight")}</label>
                <FormControl fullWidth>
                  <Select
                    labelId="weight-select-label"
                    id="weight-select-label"
                    placeholder={t("Weight")}
                    onChange={(e) => weightHandler(e.target.value)}
                    required
                    defaultValue={
                      gender === GENDER.male ? weightMale[0].value : weightFemale[0].value
                    }
                  >
                    {gender === GENDER.male
                      ? weightMale.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                      : weightFemale.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default WeightAndHeight;
