import HeadingTitle from "./customHtml/HeadingTitle";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select, FormControl, MenuItem, TextField, CircularProgress, Alert } from "@mui/material";
import countryList from "react-select-country-list";
import ConfirmLocationModal from "./modals/ConfirmLocationModal";
import { API_ENDPOINT } from "../constants/shared";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { get, set, update } from "idb-keyval";
import { useEffect } from "react";
import { MuiTelInput } from "mui-tel-input";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";

const TOMTOM_API_KEY = "YoAJnazCazdLOUHbTcoanwiPyLi7jqAA";
const TOMTOM_API_ENDPOINT = "https://api.tomtom.com/search/2";

const PersonnelInfo = () => {
  const [country, setCountry] = useState("Italy");
  const [countryCode, setCountryCode] = useState("IT");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [region, setRegion] = useState("");
  const [province, setProvince] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [language, setLanguage] = useState("en");

  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    let container = document.getElementById("container");
    if (container.scrollHeight === container.clientHeight) {
      setShowFooter(true);
    }
  }, [showFooter]);

  const navigate = useNavigate();

  const countryOptions = useMemo(() => countryList().getData(), []);

  const handleClose = () => {
    setIsOpen(false);
    setIsAlertOpen(false);
  };

  const countryChangeHandler = (e) => {
    setCountry(e.target.value);
    let cntryCode = countryOptions.filter((c) => c.label === e.target.value)[0].value;
    setCountryCode(cntryCode);
  };

  const firstNameChangeHandler = (e) => {
    setFirstName(e.target.value);
  };

  const lastNameChangeHandler = (e) => {
    setLastName(e.target.value);
  };

  const phoneNumberChangeHandler = (val) => {
    setPhoneNumber(val);
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const regionChangeHandler = (e) => {
    setRegion(e.target.value);
  };

  const provinceChangeHandler = (e) => {
    setProvince(e.target.value);
  };

  const municipalityChangeHandler = (e) => {
    setMunicipality(e.target.value);
  };

  const address1ChangeHandler = (e) => {
    setAddress1(e.target.value);
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    get("personnelInfo").then((data) => {
      if (data) {
        setFirstName(data.name ?? "");
        setLastName(data.familyName ?? "");
        setPhoneNumber(data.phone ?? "");
        setEmail(data.email ?? "");
        setAddress1(data.address1 ?? "");
        setRegion(data.region ?? "");
        setProvince(data.province ?? "");
        setMunicipality(data.municipality ?? "");
        setCountry(data.country ?? "");
        setCountryCode(data.countryCode ?? "IT");
      }
    });
    get("language").then((language) => {
      if (language) {
        setLanguage(language);
        i18n.changeLanguage(language);
      }
    });
  }, []);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    console.log("Inside submit");
    console.log(countryCode);
    console.log(region);
    console.log(province);
    console.log(municipality);

    if (countryCode && region && province && municipality) {
      const response = await axios.get(
        `${TOMTOM_API_ENDPOINT}/structuredGeocode.json?countryCode=${countryCode}&municipality=${municipality}&countrySecondarySubdivision=${province}&countrySubdivision=${region}&view=Unified&language=en-US&entityTypeSet=Municipality&key=${TOMTOM_API_KEY}`
      );
      const firstResult = response?.data?.results?.length ? response?.data?.results : [];
      console.log(firstResult);
      const firstAddress = firstResult[0];
      if (firstResult.length) {
        setCountryCode(firstAddress?.address?.countryCode);
        setCountry(firstAddress?.address?.country);
        setProvince(
          firstAddress?.address?.countrySecondarySubdivision
            ? firstAddress?.address?.countrySecondarySubdivision
            : province
        );
        setRegion(
          firstAddress?.address?.countrySubdivision ? firstAddress?.address?.countrySubdivision : ""
        );
        setMunicipality(firstAddress?.address?.municipality);
        setLatitude(firstResult[0]?.position?.lat);
        setLongitude(firstResult[0]?.position?.lon);
        setIsOpen(true);
      } else {
        setIsAlertOpen(true);
      }
    } else {
      onConfirm();
    }
  };

  const registerUser = async (data) => {
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(`${API_ENDPOINT}/register`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("User registered successfully");
      })
      .catch((err) => {
        console.log("Error in user registration: ", err);
      });
  };

  const onConfirm = async () => {
    update("wizard", (data) => {
      let newData = { ...data, step: 1 };
      return newData;
    });
    let data = {
      name: firstName,
      familyName: lastName,
      email: email,
      phone: phoneNumber,
      lat: latitude,
      lon: longitude,
      country: country,
      region: region,
      province: province,
      municipality: municipality,
      address1: address1,
      address2: address1,
    };
    set("personnelInfo", data);
    await registerUser(data);
    navigate("/intermediate");
  };

  const getCoordinates = async (position) => {
    setLongitude(position.coords.longitude);
    setLatitude(position.coords.latitude);
    let response = await axios.get(
      `${TOMTOM_API_ENDPOINT}/reverseGeocode/${position.coords.latitude},${position.coords.longitude}.json?key=${TOMTOM_API_KEY}&language=en-US&entityType=Country,Municipality,CountrySubdivision,MunicipalitySubdivision`
    );
    let firstAddress = response?.data?.addresses?.length ? response?.data?.addresses[0] : [];
    setCountryCode(firstAddress?.address?.countryCode);
    setCountry(firstAddress?.address?.country);
    setProvince(
      firstAddress?.address?.countrySecondarySubdivision
        ? firstAddress?.address?.countrySecondarySubdivision
        : ""
    );
    setRegion(
      firstAddress?.address?.countrySubdivision ? firstAddress?.address?.countrySubdivision : ""
    );
    setMunicipality(firstAddress?.address?.municipality);
    setIsLoading(false);
  };

  const onAutoLocationError = () => {
    setIsLoading(false);
  };

  const getAutoLocation = () => {
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition(getCoordinates, onAutoLocationError);
  };

  const onScroll = (e) => {
    const bottom = e.target.scrollHeight - 100 - e.target.scrollTop <= e.target.clientHeight;
    if (bottom) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  };

  const onBackButtonHandler = () => {
    navigate("/language", { state: { from: "personnelInfo" } });
  };

  return (
    <div className="imgbg-body-container m-auto" id="container" onScroll={onScroll}>
      <div className="content-wrapper">
        <HeadingTitle title="Registrazione" classNames="txt-white" />
        <form onSubmit={onSubmitHandler} id="info-form" className="txt-white">
          <div className="row mt-4 mb-4">
            <div className="col-12">
              <span className="text-danger">{t("The fields marked with * are required.")}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label htmlFor="">{t("First Name")}</label>
              <TextField
                placeholder={t("First Name")}
                className="w-100"
                value={firstName}
                onChange={firstNameChangeHandler}
              />
            </div>
            <div className="col-6">
              <label htmlFor="">{t("Last Name")}</label>
              <TextField
                placeholder={t("Last Name")}
                className="w-100"
                value={lastName}
                onChange={lastNameChangeHandler}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <label htmlFor="">{t("Email")}</label>
              <TextField
                placeholder={t("Email")}
                className="w-100"
                value={email}
                type="email"
                onChange={emailChangeHandler}
                onInvalid={(e) =>
                  e.target.setCustomValidity(t("Please enter a valid email address."))
                }
                onInput={(e) => e.target.setCustomValidity("")}
                required
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <label htmlFor="">{t("Phone Number")}</label>
              <MuiTelInput
                defaultCountry="IT"
                preferredCountries={["IT", "FR"]}
                className="w-100"
                placeholder={t("Phone Number")}
                value={phoneNumber}
                onChange={phoneNumberChangeHandler}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <label htmlFor="">{t("Address")}</label>
              <TextField
                placeholder={t("Address")}
                className="w-100"
                multiline
                minRows={2}
                maxRows={2}
                value={address1}
                onChange={address1ChangeHandler}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 localize-container">
              <p className="secondary-heading txt-white">{t("Location")}</p>
              <span
                className={`localize-btn-sm ${isLoading ? "localize-btn-loading" : ""}`}
                onClick={getAutoLocation}
              >
                {<LocationSearchingIcon />}
              </span>
              {isLoading && <CircularProgress className="cus-progress" />}
            </div>
          </div>
          {isAlertOpen && (
            <div className="row mt-4">
              <div className="col-12">
                <Alert severity="error" className="mb-3" onClose={() => setIsAlertOpen(false)}>
                  {t("Address is not valid. Please try again.")}
                </Alert>
              </div>
            </div>
          )}
          <div className="row mt-4">
            <div className="col-12">
              <label htmlFor="">{t("Country")}</label>
              <FormControl fullWidth>
                <Select
                  labelId="country-select-label"
                  id="country-select-label"
                  placeholder={t("Country")}
                  value={country}
                  onChange={countryChangeHandler}
                >
                  {countryOptions.map((option, index) => (
                    <MenuItem key={`${index}_${option.value}`} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <label htmlFor="">{t("Region")}</label>
              <TextField
                placeholder={t("Region")}
                className="w-100"
                value={region}
                onChange={regionChangeHandler}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <label htmlFor="">{t("Province")}</label>
              <TextField
                placeholder={t("Province")}
                className="w-100"
                value={province}
                onChange={provinceChangeHandler}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <label htmlFor="">{t("Municipality")}</label>
              <TextField
                placeholder={t("Municipality")}
                className="w-100"
                value={municipality}
                onChange={municipalityChangeHandler}
              />
            </div>
          </div>
          <div className="row mt-4 mb-5">
            <div className="col-12 d-flex justify-content-center">
              <button className={`primary-button`} type="submit" form-control="info-form">
                {isVerifyLoading && <CircularProgress className="cus-progress" />}
                {t("Verify")}
              </button>
            </div>
          </div>
        </form>
        <div className="row mt-4 mb-5">
          <div className="col-12 d-flex justify-content-center">
            <button className={`secondary-button`} onClick={onBackButtonHandler}>
              {t("Change the language")}
            </button>
          </div>
        </div>
        <ConfirmLocationModal
          isOpen={isOpen}
          handleClose={handleClose}
          country={country}
          province={province}
          region={region}
          municipality={municipality}
          onConfirm={onConfirm}
        />
      </div>
    </div>
  );
};

export default PersonnelInfo;
