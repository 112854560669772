import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "idb-keyval";

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    get("personnelInfo").then((personnelInfo) => {
      if (!personnelInfo) {
        setTimeout(() => {
          navigate("/language");
        }, 2000);
      } else {
        setTimeout(() => {
          navigate("/intermediate");
        }, 2000);
      }
    });
  }, []);

  return (
    <div className="splash-screen-wrapper d-flex justify-content-center align-items-center">
      <div className="splash-img sticky">
        <img src="/assets/images/Rinaldi.svg" alt="Valflex logo" className="w-100" />
      </div>
      <div className="version-txt">
        <p>Version: 1.0</p>
      </div>
    </div>
  );
};

export default SplashScreen;
