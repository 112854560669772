import HeadingTitle from "../customHtml/HeadingTitle";
import React from "react";
import { MATTRESS_TYPES } from "../../constants/shared";

const SelectMattress = ({ step, mattressHandler }) => {
  return (
    <>
      {step === 2 && (
        <div>
          <HeadingTitle title="Select Mattress" classNames="txt-white" />
          <div className="row g-0 justify-content-lg-center">
            {MATTRESS_TYPES.map((matt, index) => (
              <MattressItem
                imgPath={matt.icon}
                buttonLabel={matt.label}
                onSelect={() => mattressHandler(matt.value)}
                key={index}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const MattressItem = ({ imgPath, buttonLabel, onSelect }) => {
  return (
    <div className="wizard-mattress-item">
      <img src={`/assets/images/${imgPath}`} alt="" className="w-100" />
      <button className="primary-button" onClick={onSelect}>
        {buttonLabel}
      </button>
    </div>
  );
};

export default SelectMattress;
