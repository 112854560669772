import HeadingTitle from "./customHtml/HeadingTitle";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { API_ENDPOINT } from "../constants/shared";
import axios from "axios";
import WestIcon from "@mui/icons-material/West";
import { CircularProgress } from "@mui/material";

const sellers = [
  {
    model: "CHARLOTTE",
    tradeMark: "VALF",
    codeCli: "C004384",
    description: "show room",
    address1: "Via Via che va 1",
    zipCode: "80059",
    email: "abc@mail.com", // non presente
    name: "ACME Corporation", // non presente
    phone: "408-867-5309", // non presente
    homePage: "https://www.acme-corp.com", // non presente
    country: "Italy", // non presente
    region: "Lazio", // non presente
    province: "Roma",
    municipality: "Roma",
  },
  {
    model: "CHARLOTTE",
    tradeMark: "VALF",
    codeCli: "C004387",
    description: "show room",
    address1: "Via Via che va 1",
    zipCode: "80058",
    email: "abc@mail.com", // non presente
    name: "EMAC Corp", // non presente
    phone: "408-867-5309", // non presente
    homePage: "https://www.acme-corp.com", // non presente
    country: "Italy", // non presente
    region: "Lazio", // non presente
    province: "Roma",
    municipality: "Roma",
  },
];

const ProductSeller = () => {
  const [productSeller, setProductSeller] = useState([]);

  const [searchParams, _] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    let model = searchParams.get("model");
    let tradeMark = searchParams.get("tradeMark");
    const data = {
      model: model,
      tradeMark: tradeMark,
      codeCli: null,
      description: null,
      address1: null,
      zipCode: null,
      municipality: null,
      province: null,
    };
    getProductSeller(data);
  }, []);

  const getProductSeller = (data) => {
    axios
      .post(`${API_ENDPOINT}/resellers`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("data from reseller api");
        console.log(res.data);
        setProductSeller(res.data);
      });
    // setProductSeller(sellers); // remove this and uncomment axios call
  };

  const backBtnHandler = () => {
    navigate("/product-catalogue");
  };

  const sellerHandler = (link) => {
    // navigate user on new page
    window.open(link, "_blank");
  };

  return (
    <div className="body-container">
      <div className="content-wrapper">
        <div className="position-relative">
          <div className="back-btn" onClick={() => backBtnHandler()}>
            <WestIcon color="action" sx={{ fontSize: 35 }} />
          </div>
          <HeadingTitle title="List of sellers" />
        </div>
        {productSeller.length ? (
          <div className="row g-0 justify-content-center">
            {productSeller.map((seller, index) => (
              <SellerItem seller={seller} key={index} />
              // <SellerItem seller={seller} onSellerClick={sellerHandler} key={index} />
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center align-item-center">
            <CircularProgress color="inherit" />
          </div>
        )}
      </div>
    </div>
  );
};

const SellerItem = ({ seller, onSellerClick }) => {
  const { t } = useTranslation();

  return (
    <div className="col-12 col-sm-6 col-md-4">
      <div
        className="custom-card mb-4 cursor-pointer"
        onClick={() => onSellerClick(seller.homePage)}
      >
        <div className="seller-item-card">
          <div>
            <img src="/assets/images/product-seller.svg" alt="product-seller-icon" />
          </div>
          <div className="py-1 seller-item-card-info">
            <p className="seller-card-main-txt mb-0">{seller.description}</p>
            <p className="seller-card-secondary-txt mb-0">
              {`${seller?.municipality} ${seller?.province} ${seller?.address1}`}
            </p>
          </div>
          {/* <div className="ml-auto">
            <span className="link-txt">{t("View site")}</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProductSeller;
