import HeadingTitle from "./customHtml/HeadingTitle";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { get } from "idb-keyval";
import React, { useEffect, useState } from "react";
import { API_ENDPOINT, GENDER } from "../constants/shared";
import WestIcon from "@mui/icons-material/West";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const products = [
  {
    model: "CHARLOTTE",
    code: "qwe4342reerq",
    pic: "https://www.valflex.it/wp-content/uploads/2017/05/charlotte_PromozioneCB.jpg",
    link: "https://www.valflex.it/Prodotto/charlotte/",
    description: "Sfoderabile",
    tradeMark: "hello",
  },
  {
    model: "Jolie",
    code: "qwe4342reerq",
    pic: "https://www.valflex.it/wp-content/uploads/2017/05/JOLIE-TechTouch-VALFLEX.jpg",
    link: "https://www.valflex.it/Prodotto/jolie/",
    description: "Sfoderabile",
    tradeMark: "hello",
  },
  {
    model: "Jenifer",
    code: "qwe4342reerq",
    pic: "https://www.valflex.it/wp-content/uploads/2017/05/JENNIFER-TechTouch-VALFLEX.jpg",
    link: "https://www.valflex.it/Prodotto/jennifer/",
    description: "Sfoderabile",
    tradeMark: "hello",
  },
];

const ProductCatalogue = () => {
  const [productCatalogue, setProductCatalogue] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    get("wizard").then((wizard) => {
      console.log(wizard);
      postWizard(wizard);
    });
  }, []);

  const postWizard = (wizard) => {
    const data = {
      model: null,
      description: null,
      code: null,
      tradeMark: null,
      link: null,
      gender: wizard.gender === GENDER.male ? 2 : 1,
      height: wizard.user1.height,
      weight: wizard.user1.weight,
      posture: wizard.user1.posture,
      pic: null,
    };

    axios
      .post(`${API_ENDPOINT}/wizard`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("data from wizard api");
        console.log(res.data);
        setProductCatalogue(res.data);
      });
    // setProductCatalogue(products); // remove this and uncomment axios call
  };

  const productClickHandler = (product) => {
    navigate(`/product-sellers?model=${product.model}&tradeMark=${product.tradeMark}`);
  };

  const imgClickHandler = (link) => {
    window.open(link, "_blank");
  };

  const backBtnHandler = () => {
    navigate("/wizard");
  };

  return (
    <div className="body-container m-auto">
      <div className="content-wrapper">
        <div className="position-relative">
          <div className="back-btn" onClick={() => backBtnHandler()}>
            <WestIcon color="action" sx={{ fontSize: 35 }} />
          </div>
          <HeadingTitle title="Suggested Product Catalogue" />
        </div>
        {productCatalogue.length ? (
          <div className="row g-0 justify-content-center">
            {productCatalogue.map((product, index) => (
              <ProductItem
                product={product}
                onImageClick={imgClickHandler}
                onProductClick={productClickHandler}
                key={index}
              />
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <CircularProgress color="inherit" />
          </div>
        )}
      </div>
    </div>
  );
};

const ProductItem = ({ product, onImageClick, onProductClick }) => {
  const { t } = useTranslation();

  return (
    <div className="col-12 col-sm-6 col-md-4">
      <div className="custom-card mb-4 p-0 cursor-pointer">
        <div className="w-100" onClick={() => onImageClick(product?.link)}>
          <img
            src={`${product?.pic ? product.pic : "/assets/images/mattress-default.png"}`}
            alt=""
            className="w-100"
          />
        </div>
        <div className="px-4 py-2 d-flex justify-content-between align-items-center justify-content-center">
          <p className="mb-0 product-name">{product.model}</p>
          <span className="link-txt" onClick={() => onProductClick(product)}>
            {t("View Resellers")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductCatalogue;
