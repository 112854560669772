import React, { useEffect, useState } from "react";
import SelectGender from "./wizardSteps/SelectGender";
import SelectMattress from "./wizardSteps/SelectMattress";
import { GENDER, MATTRESS_TYPES, POSTURE_OF_SLEEPING } from "../constants/shared";
import WeightAndHeight from "./wizardSteps/WeightAndHeight";
import { useNavigate } from "react-router-dom";
import PostureOfSleeping from "./wizardSteps/PostureOfSleeping";
import { set, get } from "idb-keyval";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "./modals/ConfirmationModal";
import { useSwipeable } from "react-swipeable";

export const STEP = {
  GENDER_SELECT: 1,
  MATTRESS_SELECT: 2,
  FIRST_WEIGHT_HEIGHT_SELECT: 3,
  FIRST_SLEEPING_POSTURE_SELECT: 4,
  SECOND_WEIGHT_HEIGHT_SELECT: 5,
  SECOND_SLEEPING_POSTURE_SELECT: 6,
};

const QuestionWizard = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [gender1, setGender1] = useState(GENDER.male);
  const [gender2, setGender2] = useState(GENDER.male);
  const [mattress, setMattress] = useState(MATTRESS_TYPES[0].value);
  const [postureOfSleeping1, setPostureOfSleeping1] = useState(POSTURE_OF_SLEEPING[0].value);
  const [postureOfSleeping2, setPostureOfSleeping2] = useState(POSTURE_OF_SLEEPING[0].value);

  const [weight1, setWeight1] = useState();
  const [height1, setHeight1] = useState();

  const [weight2, setWeight2] = useState();
  const [height2, setHeight2] = useState();

  const [showAlert, setShowAlert] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [step, setStep] = useState(STEP.GENDER_SELECT);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    get("wizard").then((wizard) => {
      if (wizard) {
        setStep(wizard.step);
        setGender1(wizard?.user1?.gender ?? GENDER.male);
        setGender2(wizard?.user2?.gender ?? GENDER.male);
        setMattress(wizard?.type ?? MATTRESS_TYPES[0].value);
        setPostureOfSleeping1(wizard?.user1?.preferences?.posture ?? POSTURE_OF_SLEEPING[0].value);
        setPostureOfSleeping2(wizard?.user2?.preferences?.posture ?? POSTURE_OF_SLEEPING[0].value);
        setWeight1(wizard?.user1?.weight ?? null);
        setWeight2(wizard?.user2?.weight ?? null);
        setHeight1(wizard?.user1?.height ?? null);
        setHeight2(wizard?.user2?.height ?? null);
      }
    });
    get("language").then((language) => {
      if (language) {
        i18n.changeLanguage(language);
      }
    });
  }, []);

  useEffect(() => {
    let container = document.getElementById("container");
    if (container.scrollHeight === container.clientHeight) {
      setShowFooter(true);
    }
    const bottom = container.scrollHeight - 100 - container.scrollTop <= container.clientHeight;
    if (bottom) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  }, [showFooter]);

  const onWizardSubmit = () => {
    const wizard = {
      type: mattress,
      step: step,
      user1: {
        gender: gender1,
        height: height1,
        weight: weight1,
        posture: postureOfSleeping1,
      },
    };
    if (mattress === MATTRESS_TYPES[1].value) {
      wizard["user2"] = {
        gender: gender2,
        height: height2,
        weight: weight2,
        posture: postureOfSleeping2,
      };
    }
    set("wizard", wizard);
    navigate("/product-catalogue");
  };

  const handleOnConfirm = () => {
    setIsConfirmationModalOpen(false);
    setPostureOfSleeping2(postureOfSleeping1);
    // setMusculoskeletalPain2(musculoskeletalPain1);
    // setComfortPreference2(comfortPreference1);
    // setWeight2(weight1);
    // setHeight2(height1);
    // setWeightUnit2(weightUnit1);
    // setHeightUnit2(heightUnit1);
    onWizardSubmit();
  };

  const handleOnClose = () => {
    setIsConfirmationModalOpen(false);
    setStep(STEP.SECOND_WEIGHT_HEIGHT_SELECT);
  };

  const weightHandler1 = (val) => {
    setWeight1(val);
  };

  const heightHandler1 = (val) => {
    setHeight1(val);
  };

  const weightHandler2 = (val) => {
    setWeight2(val);
  };

  const heightHandler2 = (val) => {
    setHeight2(val);
  };

  const genderHandler = (val) => {
    setGender1(val);
    setGender2(val === GENDER.male ? GENDER.female : GENDER.male);
    nextStep();
  };

  const mattressHandler = (val) => {
    setMattress(val);
    nextStep();
  };

  const postureOfSleepingHandler1 = (val) => {
    setPostureOfSleeping1(val);
    nextStep();
  };

  const postureOfSleepingHandler2 = (val) => {
    setPostureOfSleeping2(val);
    nextStep();
  };

  const nextStep = () => {
    if (step === STEP.FIRST_WEIGHT_HEIGHT_SELECT) {
      if (!weight1 || !height1) {
        setShowAlert(true);
      } else {
        setShowFooter(false);
        setShowAlert(false);
        setStep(STEP.FIRST_SLEEPING_POSTURE_SELECT);
      }
    } else if (step === STEP.SECOND_WEIGHT_HEIGHT_SELECT) {
      if (!weight2 || !height2) {
        setShowAlert(true);
      } else {
        setShowAlert(false);
        setShowFooter(false);
        setStep(STEP.SECOND_SLEEPING_POSTURE_SELECT);
      }
    } else if (
      (step === STEP.FIRST_SLEEPING_POSTURE_SELECT && mattress !== MATTRESS_TYPES[1].value) ||
      (step === STEP.SECOND_SLEEPING_POSTURE_SELECT && mattress === MATTRESS_TYPES[1].value)
    ) {
      console.log("Submit");
      onWizardSubmit();
    } else if (
      step === STEP.FIRST_SLEEPING_POSTURE_SELECT &&
      mattress === MATTRESS_TYPES[1].value
    ) {
      setIsConfirmationModalOpen(true);
    } else {
      setStep(step + 1);
      setShowFooter(false);
    }
  };

  const prevStep = () => {
    if (step === STEP.GENDER_SELECT) {
      navigate("/profile");
    } else {
      setStep(step - 1);
      setShowFooter(false);
    }
  };

  const swHandlers = useSwipeable({
    onSwipedLeft: () => nextStep(),
    onSwipedRight: () => prevStep(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div {...swHandlers}>
      <div className="imgbg-body-container m-auto" id="container">
        <div className="wizard-content-wrapper">
          <SelectGender
            step={step}
            gender={gender1}
            genderHandler={genderHandler}
            nextStep={nextStep}
          />
          <SelectMattress step={step} mattress={mattress} mattressHandler={mattressHandler} />
          {step <= STEP.FIRST_SLEEPING_POSTURE_SELECT && (
            <>
              <WeightAndHeight
                step={step}
                weightHandler={weightHandler1}
                heightHandler={heightHandler1}
                mattress={mattress}
                showAlert={showAlert}
                gender={gender1}
              />
              <PostureOfSleeping
                step={step}
                postureOfSleeping={postureOfSleeping1}
                postureOfSleepingHandler={postureOfSleepingHandler1}
                gender={gender1}
                mattress={mattress}
              />
            </>
          )}

          {MATTRESS_TYPES[1].value === mattress && step > STEP.FIRST_SLEEPING_POSTURE_SELECT && (
            <>
              <WeightAndHeight
                step={step}
                weightHandler={weightHandler2}
                heightHandler={heightHandler2}
                mattress={mattress}
                showAlert={showAlert}
                gender={gender2}
              />
              <PostureOfSleeping
                step={step}
                postureOfSleeping={postureOfSleeping2}
                postureOfSleepingHandler={postureOfSleepingHandler2}
                gender={gender2}
                mattress={mattress}
              />
            </>
          )}
          <div
            className={`${
              (step === STEP.FIRST_WEIGHT_HEIGHT_SELECT && weight1 && height1) ||
              (step === STEP.SECOND_WEIGHT_HEIGHT_SELECT && weight2 && height2)
                ? ""
                : "d-none"
            }`}
          >
            <div className="row">
              <div className="d-flex justify-content-center mb-5">
                <button className="primary-button" onClick={nextStep}>
                  {t("Verify")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onConfirm={handleOnConfirm}
        handleClose={handleOnClose}
      />
    </div>
  );
};

export default QuestionWizard;
