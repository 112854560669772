import { Alert, Box, Modal } from "@mui/material"
import { useTranslation } from "react-i18next";
import React from "react"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


const ConfirmationModal = ({isOpen, handleClose, onConfirm}) => {

    const { t, i18n } = useTranslation();

    return (
        <React.Fragment>
            <Modal
            open={isOpen}
            onClose={handleClose}
            >
                <Box sx={style} className="custom-modal">
                    
                    <div className="d-flex justify-content-center">
                        <p className="cus-modal-header-txt">Confirmation</p>
                    </div>
                    <div className="d-flex cus-modal-txt">
                        <p>{t("Do you want to continue with identical values for 2nd Mattress?")} </p>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <button className="cus-modal-primary-btn me-2" onClick={handleClose}>{t("No")}</button>
                        <button className="cus-modal-secondary-btn ms-2" onClick={onConfirm}>{t("Yes")}</button>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default ConfirmationModal