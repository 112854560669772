import { Alert, Box, Modal } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


const ConfirmLocationModal = ({isOpen, handleClose, country, province, region, municipality, onConfirm}) => {
    const { t, i18n } = useTranslation();

    return (
        <React.Fragment>
            <Modal
            open={isOpen}
            onClose={handleClose}
            >
                <Box sx={style} className="custom-modal">
                    
                    <div className="d-flex justify-content-center">
                        <p className="cus-modal-header-txt">{t("Confirmation")}</p>
                    </div>
                    <div className="d-flex justify-content-between cus-modal-txt">
                        <div>
                            <p>{t("Country")}</p>
                            <p>{t("Region")}</p>
                            <p>{t("Province")}</p>
                            <p>{t("Municipality")}</p>
                        </div>
                        <div>
                            <p>{country}</p>
                            <p>{region}</p>
                            <p>{province}</p>
                            <p>{municipality}</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <button className="cus-modal-primary-btn me-2" onClick={handleClose}>{t("Edit")}</button>
                        <button className="cus-modal-secondary-btn ms-2" onClick={onConfirm}>{t("Confirm")}</button>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default ConfirmLocationModal