import { useNavigate } from "react-router-dom";
import { get } from "idb-keyval";
import { useTranslation } from "react-i18next"; 

const Intermediate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  get("personnelInfo").then((personnelInfo) => {
    if (!personnelInfo) {
      navigate("/");
    }
  });

  const handleWizardClick = () => {
    navigate("/wizard");
  };

  const handleWebsiteClick = () => {
    window.open("https://www.rinaldigroup.com/", "_blank").focus();
  };

  const handleAppClick = async () => {
    let lan = await get("language");
    if (!lan) {
      lan = "en";
    }
    window.location.href = `caress://app?lang=${lan}`;
  };

  return (
    <div className="imgbg-body-container">
      <div className="content-wrapper">
        <div className="intermediate-container">
          <div className="intermediate-card blue" onClick={handleAppClick}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 14 14">
              <g id="phone-mobile-phone--android-phone-mobile-device-smartphone-iphone">
                <path
                  id="Vector"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 0.4642857142857143h-6.5c-0.5128314285714286 0 -0.9285714285714286 0.41573535714285714 -0.9285714285714286 0.9285714285714286v10.214285714285715c0 0.51285 0.41574 0.9285714285714286 0.9285714285714286 0.9285714285714286h6.5c0.51285 0 0.9285714285714286 -0.4157214285714286 0.9285714285714286 -0.9285714285714286v-10.214285714285715c0 -0.5128360714285715 -0.4157214285714286 -0.9285714285714286 -0.9285714285714286 -0.9285714285714286Z"
                  stroke-width="1"
                ></path>
                <path
                  id="Vector_2"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.035714285714286 10.214285714285715h0.9285714285714286"
                  stroke-width="1"
                ></path>
              </g>
            </svg>
            <div>
              <span>
                {t("Option1")}
              </span>
            </div>
          </div>
          <div className="intermediate-card cyan" onClick={handleWizardClick}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 14 14">
              <g id="empty-clipboard--work-plain-clipboard-task-list-company-office">
                <path
                  id="Vector"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.892857142857143 0.4642857142857143h-2.7857142857142856c-0.5128314285714286 0 -0.9285714285714286 0.41573535714285714 -0.9285714285714286 0.9285714285714286V1.8571428571428572c0 0.5128314285714286 0.41574 0.9285714285714286 0.9285714285714286 0.9285714285714286h2.7857142857142856c0.5128314285714286 0 0.9285714285714286 -0.41574 0.9285714285714286 -0.9285714285714286v-0.4642857142857143c0 -0.5128360714285715 -0.41574 -0.9285714285714286 -0.9285714285714286 -0.9285714285714286Z"
                  stroke-width="1"
                ></path>
                <path
                  id="Vector_2"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.053571428571429 1.3928571428571428h1.3928571428571428c0.24625714285714287 0 0.48248571428571424 0.09783428571428571 0.6565928571428571 0.2719692857142857 0.17410714285714285 0.17414428571428572 0.27197857142857146 0.4103264285714286 0.27197857142857146 0.6566021428571429v9.285714285714286c0 0.24625714285714287 -0.09787142857142857 0.48248571428571424 -0.27197857142857146 0.6565928571428571s-0.4103357142857143 0.27197857142857146 -0.6565928571428571 0.27197857142857146h-7.892857142857143c-0.2462757142857143 0 -0.48245785714285716 -0.09787142857142857 -0.6566021428571429 -0.27197857142857146 -0.174135 -0.17410714285714285 -0.2719692857142857 -0.4103357142857143 -0.2719692857142857 -0.6565928571428571v-9.285714285714286c0 -0.2462757142857143 0.09783428571428571 -0.48245785714285716 0.2719692857142857 -0.6566021428571429C2.0711135714285716 1.4906914285714286 2.3072957142857144 1.3928571428571428 2.553571428571429 1.3928571428571428h1.3928571428571428"
                  stroke-width="1"
                ></path>
              </g>
            </svg>
            <div>
              <span>
                {t("Option2")}
              </span>
            </div>
          </div>
          <div className="intermediate-card pink" onClick={handleWebsiteClick}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 14 14">
              <g id="web--server-world-internet-earth-www-globe-worldwide-web-network">
                <path
                  id="Vector"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.5 12.535714285714286c3.3334785714285715 0 6.035714285714286 -2.702235714285714 6.035714285714286 -6.035714285714286C12.535714285714286 3.1665678571428573 9.833478571428572 0.4642857142857143 6.5 0.4642857142857143 3.1665678571428573 0.4642857142857143 0.4642857142857143 3.1665678571428573 0.4642857142857143 6.5c0 3.3334785714285715 2.702282142857143 6.035714285714286 6.035714285714286 6.035714285714286Z"
                  stroke-width="1"
                ></path>
                <path
                  id="Vector_2"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M0.4642857142857143 6.5h12.071428571428571"
                  stroke-width="1"
                ></path>
                <path
                  id="Vector_3"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.821428571428571 6.5c-0.11402857142857144 2.207205 -0.9270114285714286 4.320921428571428 -2.3214285714285716 6.035714285714286C5.105582857142857 10.82092142857143 4.2926 8.707205 4.178571428571429 6.5c0.11402857142857144 -2.207205 0.9270114285714286 -4.320967857142857 2.3214285714285716 -6.035714285714286C7.894417142857144 2.179032142857143 8.7074 4.292795 8.821428571428571 6.5v0Z"
                  stroke-width="1"
                ></path>
              </g>
            </svg>
            <div>
              <span>
                {t("Option3")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intermediate;
