import HeadingTitle from "../customHtml/HeadingTitle";
import React from "react";
import { POSTURE_OF_SLEEPING } from "../../constants/shared";
import { STEP } from "../QuestionQizard";

const PostureOfSleeping = ({
  step,
  postureOfSleeping,
  postureOfSleepingHandler,
  gender,
  mattress,
}) => {
  return (
    <>
      {(step === STEP.FIRST_SLEEPING_POSTURE_SELECT ||
        step === STEP.SECOND_SLEEPING_POSTURE_SELECT) && (
        <div>
          <HeadingTitle title={"Select Posture of Sleeping"} classNames="txt-white" />
          <div className="wizard-sleep-pos-container">
            {POSTURE_OF_SLEEPING.map((pos, index) => (
              <PostureItem
                onSelect={() => postureOfSleepingHandler(pos.value)}
                imgPath={`${pos.icon}-${gender}.png`}
                key={index}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const PostureItem = ({ imgPath, onSelect }) => {
  return <img src={`/assets/images/${imgPath}`} alt="" className="w-100" onClick={onSelect} />;
};

export default PostureOfSleeping;
